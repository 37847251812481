import React from "react";
import { graphql, useFragment } from "react-relay";

import PublishPackageLogo from "@assets/icons/PublishPackageLogo";
import DashboardActivityItemHeader from "@components/Dashboard/DashboardActivityItemHeader";
import { PackageVersionActivityItem_data$key } from "@generated/PackageVersionActivityItem_data.graphql";

import PackageVersionActivityCard from "./PackageVersionActivityCard";

const PackageVersionActivityItem_dataFragment = graphql`
  fragment PackageVersionActivityItem_data on ActivityEvent {
    ...DashboardActivityItemHeader_data
    id
    body {
      text
      ranges {
        entity {
          ... on PackageVersion {
            id
            ...PackageVersionActivityCard_data
          }
        }
      }
    }
  }
`;

interface PackageVersionActivityItemProps {
  fragmentRef: PackageVersionActivityItem_data$key;
  messageOnly?: boolean;
}

const PackageVersionActivityItem: React.FC<PackageVersionActivityItemProps> = ({
  fragmentRef,
  messageOnly = false,
}) => {
  const data = useFragment(PackageVersionActivityItem_dataFragment, fragmentRef);
  if (!data?.id) return null;
  const PackageVersionActivityCardFragmentRef = data.body?.ranges?.find((range) => !!range.entity?.id)?.entity!;
  return (
    <div className="flex w-full flex-col items-start justify-start gap-3">
      <DashboardActivityItemHeader
        fragmentRef={data}
        icon={<PublishPackageLogo className="w-[10px] min-w-[10px] stroke-[#FBFBFC]" />}
        vertical={messageOnly}
      />
      {messageOnly ? null : <PackageVersionActivityCard fragmentRef={PackageVersionActivityCardFragmentRef} />}
    </div>
  );
};

export default PackageVersionActivityItem;
