import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const Fire = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("fill-[#FBFBFC]", props?.className))}
    >
      <path d="M5.835.115c-.002-.051-.057-.084-.1-.058-2.31 1.38-2.268 4.842-2.24 5.47.002.047-.043.08-.086.06-.267-.125-1.032-.599-1.074-1.91-.001-.051-.056-.084-.1-.058C.897 4.412 0 5.876 0 7.5 0 9.985 2.09 12 4.667 12s4.666-2.015 4.666-4.5c0-3.61-3.413-4.387-3.498-7.385z" />
    </svg>
  );
};

export default Fire;
