/**
 * @generated SignedSource<<9b5b349241f2f5df80eaaeef8f9b893f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlogPostActivityCard_data$data = {
  readonly coverImageUrl: string | null | undefined;
  readonly id: string;
  readonly slug: string;
  readonly tags: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }> | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "BlogPostActivityCard_data";
};
export type BlogPostActivityCard_data$key = {
  readonly " $data"?: BlogPostActivityCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlogPostActivityCard_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlogPostActivityCard_data",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BlogPostTag",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverImageUrl",
      "storageKey": null
    }
  ],
  "type": "BlogPost",
  "abstractKey": null
};
})();

(node as any).hash = "c61472f997bf5a7a9e4e4bae716eef0f";

export default node;
