/**
 * @generated SignedSource<<b2e2b3e13dff8916e7984418ed405424>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppRowCard_deployAppVersion$data = {
  readonly app: {
    readonly name: string;
    readonly owner: {
      readonly globalName: string;
    };
    readonly url: string;
  };
  readonly clientName: string;
  readonly favicon: any | null | undefined;
  readonly isActive: boolean;
  readonly publishedBy: {
    readonly avatar: string;
    readonly fullName: string;
    readonly username: string;
  };
  readonly screenshot: any | null | undefined;
  readonly sourcePackage: {
    readonly icon: string;
    readonly name: string;
    readonly owner: {
      readonly globalName: string;
    };
    readonly packageName: string;
  };
  readonly url: string;
  readonly " $fragmentSpreads": FragmentRefs<"AppCardOptionsDropdown_data">;
  readonly " $fragmentType": "AppRowCard_deployAppVersion";
};
export type AppRowCard_deployAppVersion$key = {
  readonly " $data"?: AppRowCard_deployAppVersion$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppRowCard_deployAppVersion">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "owner",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "globalName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppRowCard_deployAppVersion",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppCardOptionsDropdown_data"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favicon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Package",
      "kind": "LinkedField",
      "name": "sourcePackage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "packageName",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeployApp",
      "kind": "LinkedField",
      "name": "app",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "publishedBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatar",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "screenshot",
      "storageKey": null
    }
  ],
  "type": "DeployAppVersion",
  "abstractKey": null
};
})();

(node as any).hash = "15d9c74854c204f1a8efb53969e529f0";

export default node;
