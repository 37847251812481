import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const PublishPackageLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("stroke-[#FA9E33]", props?.className))}
    >
      <g filter="url(#prefix__filter0_i_1575_38929)">
        <path d="M8 1a7 7 0 00-6.78 5.26" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <g filter="url(#prefix__filter1_i_1575_38929)">
        <path d="M1.938 11.5a7 7 0 007.944 3.242" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <g filter="url(#prefix__filter2_i_1575_38929)">
        <path d="M14.062 11.5a7 7 0 00-1.164-8.501" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <filter
          id="prefix__filter0_i_1575_38929"
          x={0.22}
          y={0}
          width={8.78}
          height={8}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={0.741} />
          <feGaussianBlur stdDeviation={0.37} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="soft-light" in2="shape" result="effect1_innerShadow_1575_38929" />
        </filter>
        <filter
          id="prefix__filter1_i_1575_38929"
          x={0.938}
          y={10.5}
          width={9.945}
          height={6.241}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={0.741} />
          <feGaussianBlur stdDeviation={0.37} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="soft-light" in2="shape" result="effect1_innerShadow_1575_38929" />
        </filter>
        <filter
          id="prefix__filter2_i_1575_38929"
          x={11.898}
          y={1.999}
          width={4.103}
          height={11.242}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={0.741} />
          <feGaussianBlur stdDeviation={0.37} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="soft-light" in2="shape" result="effect1_innerShadow_1575_38929" />
        </filter>
      </defs>
    </svg>
  );
};

export default PublishPackageLogo;
