import React from "react";

import { cn } from "@libs/helper";

interface SidebarItemCardProps {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}
const SidebarItemCard: React.FC<SidebarItemCardProps> = ({ children, className, ...otherProps }) => {
  return (
    <div
      {...otherProps}
      className={cn(
        "rounded-[8px] border-[1px] border-mischka py-[12px] px-[16px] transition-all duration-[300ms] ease-in-out hover:bg-[#0F0518]/[5%] active:bg-[#0F0518]/[7%]",
        className
      )}
    >
      {children}
    </div>
  );
};

export default SidebarItemCard;
