import React from "react";
import { graphql, useFragment } from "react-relay";

import Fire from "@assets/Fire";
import { DashboardActivityItem_data$key } from "@generated/DashboardActivityItem_data.graphql";

import DashboardActivityItemHeader from "../DashboardActivityItemHeader";
import AppActivityItem from "./AppActivityItem";
import AppVersionActivityItem from "./AppVersionActivityItem";
import BlogPostActivityItem from "./BlogPostActivityItem";
import PackageActivityItem from "./PackageActivityItem";
import PackageVersionActivityItem from "./PackageVersionActivityItem";

const DashboardActivityItem_dataFragment = graphql`
  fragment DashboardActivityItem_data on ActivityEvent {
    ...DashboardActivityItemHeader_data
    ...PackageVersionActivityItem_data
    ...PackageActivityItem_data
    ...AppActivityItem_data
    ...AppVersionActivityItem_data
    ...BlogPostActivityItem_data
    body {
      text
      ranges {
        packageEntity: entity {
          ... on Package {
            id
          }
        }
        packageVersionEntity: entity {
          ... on PackageVersion {
            id
          }
        }
        blogPostEntity: entity {
          ... on BlogPost {
            id
          }
        }
        appEntity: entity {
          ... on DeployApp {
            id
          }
        }
        appVersionEntity: entity {
          ... on DeployAppVersion {
            id
          }
        }
      }
    }
  }
`;

interface DashboardActivityItemProps {
  fragmentRef: DashboardActivityItem_data$key;
  messageOnly?: boolean;
}

const DashboardActivityItem: React.FC<DashboardActivityItemProps> = ({ fragmentRef, messageOnly = false }) => {
  const data = useFragment(DashboardActivityItem_dataFragment, fragmentRef);
  if (!data?.body?.ranges?.length) return null;

  const isActivityMessageAboutPackage = data.body.ranges.some((range) => range?.packageEntity?.id),
    isActivityMessageAboutPackageVersion = data.body.ranges.some((range) => range?.packageVersionEntity?.id),
    isActivityMessageAboutApp = data.body.ranges.some((range) => range?.appEntity?.id),
    isActivityMessageAboutAppVersion = data.body.ranges.some((range) => range?.appVersionEntity?.id),
    isActivityMessageAboutBlogPost = data.body.ranges.some((range) => range?.blogPostEntity?.id),
    isActivityMessageAboutWasmer = data.body.text.includes("recommend") || data.body.text.includes("join");

  if (isActivityMessageAboutPackageVersion) {
    return <PackageVersionActivityItem fragmentRef={data} messageOnly={messageOnly} />;
  } else if (isActivityMessageAboutPackage) {
    return <PackageActivityItem fragmentRef={data} messageOnly={messageOnly} />;
  } else if (isActivityMessageAboutApp) {
    return <AppActivityItem fragmentRef={data} messageOnly={messageOnly} />;
  } else if (isActivityMessageAboutAppVersion) {
    return <AppVersionActivityItem fragmentRef={data} messageOnly={messageOnly} />;
  } else if (isActivityMessageAboutBlogPost) {
    return <BlogPostActivityItem fragmentRef={data} messageOnly={messageOnly} />;
  } else if (isActivityMessageAboutWasmer) {
    return (
      <DashboardActivityItemHeader fragmentRef={data} icon={<Fire className="w-[9px]" />} vertical={messageOnly} />
    );
  } else {
    return <DashboardActivityItemHeader fragmentRef={data} />;
  }
};

export default DashboardActivityItem;
