import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Image from "next/image";
import React from "react";
import { graphql, useFragment } from "react-relay";

import RenderRanges from "@components/RenderRanges";
import { DashboardActivityItemHeader_data$key } from "@generated/DashboardActivityItemHeader_data.graphql";
import { cn } from "@libs/helper";

import styles from "./index.module.css";

dayjs.extend(relativeTime);

const DashboardActivityItemHeader_dataFragment = graphql`
  fragment DashboardActivityItemHeader_data on ActivityEvent {
    body {
      ...RenderRanges_data
    }
    id
    actorIcon
    createdAt
  }
`;

interface DashboardActivityItemHeaderProps {
  fragmentRef: DashboardActivityItemHeader_data$key;
  icon?: React.ReactNode;
  vertical?: boolean;
}

const DashboardActivityItemHeader: React.FC<DashboardActivityItemHeaderProps> = ({
  fragmentRef,
  icon,
  vertical = false,
}) => {
  const data = useFragment(DashboardActivityItemHeader_dataFragment, fragmentRef);

  const activityOwnerAvatar = data.actorIcon;

  if (!data?.id) return null;

  return (
    <div
      className={cn("flex w-full items-center justify-start gap-3", vertical && "items-start ")}
      data-cy="activity-item-header"
    >
      <div className="relative">
        <Image
          src={activityOwnerAvatar}
          alt={activityOwnerAvatar + " avatar"}
          width={24}
          height={24}
          className="h-[24px] w-[24px] min-w-[24px] rounded-full"
          crossOrigin="anonymous"
        />
        <div className="absolute top-1/2 left-1/2 flex h-[18px] w-[18px] items-center justify-center rounded-full bg-black outline outline-[#FBFBFC]">
          {icon}
        </div>
      </div>
      <div
        className={cn(
          "flex w-full items-center justify-between gap-2",
          vertical && "flex-col items-start justify-start gap-1"
        )}
      >
        <p
          className={cn(
            "w-full overflow-clip break-keep font-gilroy text-[13px] font-[400] leading-[140%] text-[#0F0518]/50 tablet:max-w-[40vw]",
            !vertical && "truncate"
          )}
          data-cy="activity-item-header-message"
        >
          <RenderRanges fragmentRef={data.body} />
        </p>
        <p className={styles.DashboardActivityItemHeaderTime} data-cy="activity-item-header-time">
          {dayjs(data?.createdAt).fromNow(true)} ago
        </p>
      </div>
    </div>
  );
};

export default DashboardActivityItemHeader;
