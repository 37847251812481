import React from "react";
import { graphql, useFragment } from "react-relay";

import PublishPackageLogo from "@assets/icons/PublishPackageLogo";
import DashboardActivityItemHeader from "@components/Dashboard/DashboardActivityItemHeader";
import { BlogPostActivityItem_data$key } from "@generated/BlogPostActivityItem_data.graphql";

import BlogPostActivityCard from "./BlogPostActivityCard";

const BlogPostActivityItem_dataFragment = graphql`
  fragment BlogPostActivityItem_data on ActivityEvent {
    ...DashboardActivityItemHeader_data
    id
    body {
      text
      ranges {
        entity {
          ... on BlogPost {
            id
            ...BlogPostActivityCard_data
          }
        }
      }
    }
  }
`;

interface BlogPostActivityItemProps {
  fragmentRef: BlogPostActivityItem_data$key;
  messageOnly?: boolean;
}

const BlogPostActivityItem: React.FC<BlogPostActivityItemProps> = ({ fragmentRef, messageOnly = false }) => {
  const data = useFragment(BlogPostActivityItem_dataFragment, fragmentRef);
  if (!data?.id) return null;
  const blogPostEntity = data.body?.ranges?.find((range) => !!range.entity?.id)?.entity;
  return (
    <div className="flex w-full flex-col items-start justify-start gap-3">
      <DashboardActivityItemHeader
        fragmentRef={data}
        icon={<PublishPackageLogo className="w-[10px] min-w-[10px] stroke-[#FBFBFC]" />}
        vertical={messageOnly}
      />
      {messageOnly ? null : <BlogPostActivityCard fragmentRef={blogPostEntity!} />}
    </div>
  );
};

export default BlogPostActivityItem;
