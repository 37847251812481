import React from "react";
import { graphql, useFragment, usePaginationFragment } from "react-relay";

import NewLoading from "@components/NewLoading";
import { ActivityListForNamespace_data$key } from "@generated/ActivityListForNamespace_data.graphql";
import { ActivityList_data$data, ActivityList_data$key } from "@generated/ActivityList_data.graphql.js";

import DashboardActivityItem from "../DashboardActivityItem";
import styles from "./index.module.css";
import { ActivityListForUser_data$key } from "@generated/ActivityListForUser_data.graphql";

const ActivityListForUser_dataFragment = graphql`
  fragment ActivityListForUser_data on User
  @argumentDefinitions(count: { type: Int, defaultValue: 6 }, cursor: { type: "String", defaultValue: null })
  @refetchable(queryName: "ActivityList_activityPaginationQuery") {
    activity: publicActivity(first: $count, after: $cursor) @connection(key: "ActivityList_activity") {
      edges {
        node {
          id
          ...DashboardActivityItem_data
        }
      }
    }
  }
`;

const ActivityListForNamespace_dataFragment = graphql`
  fragment ActivityListForNamespace_data on Namespace
  @argumentDefinitions(count: { type: Int, defaultValue: 6 }, cursor: { type: "String", defaultValue: null })
  @refetchable(queryName: "ActivityListForNamespace_activityPaginationQuery") {
    activity: publicActivity(first: $count, after: $cursor) @connection(key: "ActivityListForNamespace_activity") {
      edges {
        node {
          id
          ...DashboardActivityItem_data
        }
      }
    }
  }
`;

const ActivityList_dataFragment = graphql`
  fragment ActivityList_data on GlobalObject
  @argumentDefinitions(count: { type: Int, defaultValue: 6 }, cursor: { type: "String", defaultValue: null }) {
    __typename
    ... on User {
      ...ActivityListForUser_data @arguments(count: $count, cursor: $cursor)
    }
    ... on Namespace {
      ...ActivityListForNamespace_data @arguments(count: $count, cursor: $cursor)
    }
  }
`;

interface UserActivityListProps {
  type: "User";
  fragmentRef: ActivityListForUser_data$key;
}

interface NamespaceActivityListProps {
  type: "Namespace";
  fragmentRef: ActivityListForNamespace_data$key;
}

type ActivityListProps = (UserActivityListProps | NamespaceActivityListProps) & {
  messageOnly?: boolean;
};

const getPaginationFragment = (props: UserActivityListProps | NamespaceActivityListProps) => {
  if (props.type == "User") {
    return usePaginationFragment(ActivityListForUser_dataFragment, props.fragmentRef);
  } else {
    return usePaginationFragment(ActivityListForNamespace_dataFragment, props.fragmentRef);
  }
};

const ActivityList: React.FC<ActivityListProps> = ({ messageOnly = false, ...props }) => {
  var { data, loadNext, hasNext } = getPaginationFragment(props);
  const activityEventsList = data?.activity.edges.map((edge) => edge?.node);

  return (
    <div className="flex w-full flex-col items-start justify-start gap-8" data-cy="activity-list">
      {activityEventsList?.map((elem) => {
        return <DashboardActivityItem key={elem?.id} fragmentRef={elem!} messageOnly={messageOnly} />;
      })}
      {!hasNext ? null : (
        <div className="flex w-full items-center justify-center">
          <NewLoading onVisible={() => loadNext(10)} className={styles.loadingIcon} />
        </div>
      )}
    </div>
  );
};

export default ActivityList;
