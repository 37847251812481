import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const Close = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("stroke-[#FBFBFC]", props?.className))}
    >
      <path d="M1 1l12 12m0-12L1 13" strokeWidth={1.5} strokeLinecap="round" />
    </svg>
  );
};

export default Close;
