import Link from "next/link";
import React from "react";

import PublishPackageLogo from "@assets/icons/PublishPackageLogo";

import styles from "./index.module.css";

const PublishPackageButton: React.FC = () => {
  const publishPackagePageLink = "https://docs.wasmer.io/registry/cli#publish-a-pacakge-to-wasmer";
  return (
    <Link href={publishPackagePageLink} target="_blank" className="inline-block">
      <div className={styles.wrapper} data-cy="publish-package-button">
        <PublishPackageLogo className="h-[14px] min-h-[14px] w-[14px] min-w-[14px]" />
        <span className={styles.text}>Publish package</span>
      </div>
    </Link>
  );
};

export default PublishPackageButton;
