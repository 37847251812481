import React from "react";
import { graphql, useFragment } from "react-relay";
import Image from "next/image";
import Link from "next/link";

import CliSource from "@assets/icons/CliSource";
import DeployIndicator from "@assets/icons/DeployIndicator";
import ThreeDotsIcon from "@assets/icons/ThreeDotsIcon";
import AppTagOwner from "@components/AppDetailPage/AppDetailSidebar/AppTagOwner";
import SharedAppTag from "@components/shared/AppTag";

import Divider from "@components/Divider";
import IconButton from "@components/shared/Buttons/IconButton";
import Card from "@components/shared/Cards/Card";
import TextLink from "@components/shared/Links/TextLink";
import { AppRowCard_deployAppVersion$key } from "@generated/AppRowCard_deployAppVersion.graphql";

import AppCardOptionsDropdown from "./AppCardOptionsDropdown";
import css from "./AppRow.module.css";
import AppTag from "./AppTag";
import cssAppOwner from "./AppTagOwner.module.css";
import appUrl from "./appUrl.module.css";
import cssDeployBy from "./deployBy.module.css";

const AppRow = (props: { appVersion: AppRowCard_deployAppVersion$key }) => {
  const appVersion = useFragment(
    graphql`
      fragment AppRowCard_deployAppVersion on DeployAppVersion {
        ...AppCardOptionsDropdown_data
        favicon
        sourcePackage {
          icon
          name
          packageName
          owner {
            globalName
          }
        }
        clientName
        isActive
        app {
          name
          owner {
            globalName
          }
          url
        }
        publishedBy {
          avatar
          fullName
          username
        }
        url
        screenshot
      }
    `,
    props.appVersion
  );
  if (!appVersion) return null;
  const url = appVersion.url!;
  const appUrlOrVersionedUrl = appVersion.isActive ? appVersion.app.url : url;

  return (
    <Card className={css.wrapper}>
      <div className="relative aspect-[1.6] flex-none overflow-hidden rounded-md border-[1px] border-blue-grey-50 max-sm:h-auto max-sm:w-full sm:h-full">
        <Link
          className="absolute h-full w-full"
          href={`/apps/${appVersion.app.owner.globalName}/${appVersion.app.name}`}
        >
          <Image
            alt={`Screenshot of ${appVersion.app.name}`}
            src={appVersion.screenshot}
            width="960"
            height="600"
            fill={false}
          />
        </Link>
      </div>
      <div className="relative flex w-full flex-auto flex-col gap-[16px] overflow-hidden pt-[16px]">
        {/* <div className={css.extMenu}>
          <IconButton icon={<ThreeDotsIcon />} className={css.tmp_hidden} />
        </div> */}
        <div className={css.appTags}>
          <AppTag
            fallbackIcon={appVersion.sourcePackage?.icon}
            icon={appVersion.favicon}
            owner={appVersion.app.owner.globalName}
            name={appVersion.app.name}
            className={css.appTag}
          />
          <Divider className={css.appTagsDivider} />
          <div className={css.appOwner}>
            <TextLink text={appUrlOrVersionedUrl} href={appUrlOrVersionedUrl} theme={appUrl} />
          </div>
          {/* <AppCardOptionsDropdown fragmentRef={appVersion} /> */}
        </div>
        <div className={css.footer}>
          <div className={css.content}>
            <div className={css.deployInfo}>
              <div className={css.deployViaWrap}>
                <span className={css.deployViaUsername}>{appVersion.publishedBy.username}</span>{" "}
                <span className={css.deployViaLabel}>deployed via</span>
                <CliSource />
                <span className={css.deployVia}>{appVersion.clientName}</span>
              </div>
            </div>
          </div>

          <div className={css.indicatorWrap}>{appVersion.isActive && <DeployIndicator />}</div>
        </div>
      </div>
    </Card>
  );
};
export default AppRow;
