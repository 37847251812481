import React from "react";
import { graphql, useFragment } from "react-relay";

import PackageCard from "@components/PackageCard";
import { PackageActivityCard_data$key } from "@generated/PackageActivityCard_data.graphql";

import styles from "./index.module.css";

const PackageActivityCard_dataFragment = graphql`
  fragment PackageActivityCard_data on Package {
    id
    lastVersion {
      ...PackageCard_data
    }
  }
`;

interface PackageActivityCardProps {
  fragmentRef: PackageActivityCard_data$key;
}

const PackageActivityCard: React.FC<PackageActivityCardProps> = ({ fragmentRef }) => {
  const data = useFragment(PackageActivityCard_dataFragment, fragmentRef);
  return <PackageCard className={styles.pkgCardClassName} fragmentRef={data.lastVersion!} forceMobileLayout={true} />;
};

export default PackageActivityCard;
