/**
 * @generated SignedSource<<b7b8ccf4048cadb26f1a19e61113bdac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PackageVersionActivityItem_data$data = {
  readonly body: {
    readonly ranges: ReadonlyArray<{
      readonly entity: {
        readonly id?: string;
        readonly " $fragmentSpreads": FragmentRefs<"PackageVersionActivityCard_data">;
      };
    }>;
    readonly text: string;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardActivityItemHeader_data">;
  readonly " $fragmentType": "PackageVersionActivityItem_data";
};
export type PackageVersionActivityItem_data$key = {
  readonly " $data"?: PackageVersionActivityItem_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PackageVersionActivityItem_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PackageVersionActivityItem_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardActivityItemHeader_data"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EventBody",
      "kind": "LinkedField",
      "name": "body",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NodeBodyRange",
          "kind": "LinkedField",
          "name": "ranges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "entity",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "PackageVersionActivityCard_data"
                    }
                  ],
                  "type": "PackageVersion",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ActivityEvent",
  "abstractKey": null
};
})();

(node as any).hash = "29c93b286ded4a00a93ca1f864087099";

export default node;
