import React from "react";
import { graphql, useFragment } from "react-relay";

import AppRow from "@components/shared/Cards/AppRow";
import { AppActivityCard_data$key } from "@generated/AppActivityCard_data.graphql";

const AppActivityCard_dataFragment = graphql`
  fragment AppActivityCard_data on DeployApp {
    id
    activeVersion {
      ...AppRowCard_deployAppVersion
    }
  }
`;

interface AppActivityCardProps {
  fragmentRef: AppActivityCard_data$key;
}

const AppActivityCard: React.FC<AppActivityCardProps> = ({ fragmentRef }) => {
  const data = useFragment(AppActivityCard_dataFragment, fragmentRef);

  return <AppRow appVersion={data.activeVersion} />;
};

export default AppActivityCard;
