import Image from "next/image";
import Link from "next/link";
import React from "react";
import { graphql, useFragment } from "react-relay";

import ContentCard from "@components/shared/Cards/ContentCard";
import { BlogPostActivityCard_data$key } from "@generated/BlogPostActivityCard_data.graphql";

import styles from "./index.module.css";

const BlogPostActivityCard_dataFragment = graphql`
  fragment BlogPostActivityCard_data on BlogPost {
    id
    slug
    title
    tags {
      id
      name
    }
    coverImageUrl
  }
`;

interface BlogPostActivityCardProps {
  fragmentRef: BlogPostActivityCard_data$key;
}

const BlogPostTag: React.FC<{ tag: string }> = ({ tag }) => {
  const tagLink = `/posts?tags=${tag}`;
  return (
    <Link href={tagLink}>
      <div className="flex w-fit items-center justify-center rounded-[4px] bg-[#0F05181A]/[10%] py-[4px] px-[6px]">
        <p className="font-gilroy text-[13px] font-[600] capitalize leading-normal text-[#0F0518]/50">{tag}</p>
      </div>
    </Link>
  );
};

const BlogPostActivityCard: React.FC<BlogPostActivityCardProps> = ({ fragmentRef }) => {
  const data = useFragment(BlogPostActivityCard_dataFragment, fragmentRef);
  const title = data?.title,
    slug = data?.slug,
    coverImageUrl = data?.coverImageUrl,
    tags = data?.tags;

  return (
    <ContentCard className={styles.BlogPostActivityCardWrapper}>
      <div className="flex w-full flex-col gap-2 py-4">
        <div className="flex w-full items-center justify-start gap-2">
          {tags?.map((tag) => <BlogPostTag key={tag.id} tag={tag.name} />)}
        </div>
        <Link href={`/posts/${slug}`}>
          <p className="font-gilroy text-[16px] font-[600] leading-[140%] text-[#0F0518] hover:underline">{title}</p>
        </Link>
      </div>
      {/* TODO: add 3 dots menu */}
      {!coverImageUrl ? null : (
        <Image
          src={coverImageUrl}
          alt={title}
          width={202}
          height={106}
          className="h-[106px] min-h-[106px] w-full rounded-[2px] object-contain tablet:max-w-[202px]"
          crossOrigin="anonymous"
        />
      )}
    </ContentCard>
  );
};

export default BlogPostActivityCard;
