/**
 * @generated SignedSource<<c82483df0d78adda001eb588d3fe7fcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PackageVersionActivityCard_data$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"PackageCard_data">;
  readonly " $fragmentType": "PackageVersionActivityCard_data";
};
export type PackageVersionActivityCard_data$key = {
  readonly " $data"?: PackageVersionActivityCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PackageVersionActivityCard_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PackageVersionActivityCard_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PackageCard_data"
    }
  ],
  "type": "PackageVersion",
  "abstractKey": null
};

(node as any).hash = "1600d05deeba417a323ecf4dc0c3bcf7";

export default node;
