/**
 * @generated SignedSource<<d7a38320087c08b5d40619bf2e3e2228>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardActivityItemHeader_data$data = {
  readonly actorIcon: string;
  readonly body: {
    readonly " $fragmentSpreads": FragmentRefs<"RenderRanges_data">;
  };
  readonly createdAt: any;
  readonly id: string;
  readonly " $fragmentType": "DashboardActivityItemHeader_data";
};
export type DashboardActivityItemHeader_data$key = {
  readonly " $data"?: DashboardActivityItemHeader_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardActivityItemHeader_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardActivityItemHeader_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EventBody",
      "kind": "LinkedField",
      "name": "body",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RenderRanges_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actorIcon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "ActivityEvent",
  "abstractKey": null
};

(node as any).hash = "2ced18f0e472e5f59c93aea877ef68ec";

export default node;
