"use client";

import { useRouter } from "next/navigation";
import React, { useState } from "react";
import { graphql, useMutation } from "react-relay";

import Close from "@assets/icons/Close";
import ProfilePicture from "@components/settingPages/Account/ProfilePicture";
import ConfirmButton from "@components/shared/Buttons/ConfirmButton";
import DefaultInputBox from "@components/shared/InputBox/DefaultInputBox";
import InputBoxWithAdorment from "@components/shared/InputBox/InputBoxWithAdorment";
import LoadingIcon from "@components/shared/LoadingIcon";
import { ToasterAlertSuccess, ToasterAlertWarning } from "@components/shared/ToasterAlert";
import {
  AddNamespaceModal_createNamespaceMutation,
  AddNamespaceModal_createNamespaceMutation$variables,
} from "@generated/AddNamespaceModal_createNamespaceMutation.graphql";
import { Dialog, Transition } from "@headlessui/react";
import { cn } from "@libs/helper";

import css from "./AddNamespaceModal.module.css";

const AddNamespaceModalCreateNamespaceMutation = graphql`
  mutation AddNamespaceModal_createNamespaceMutation($input: CreateNamespaceInput!) {
    createNamespace(input: $input) {
      namespace {
        id
        name
        displayName
        globalName
        avatar
        isAdmin: viewerHasRole(role: ADMIN)
        isEditor: viewerHasRole(role: EDITOR)
        isViewer: viewerHasRole(role: VIEWER)
      }
      user {
        id
        namespaces {
          edges {
            node {
              id
              name
              displayName
              globalName
              avatar
              isAdmin: viewerHasRole(role: ADMIN)
              isEditor: viewerHasRole(role: EDITOR)
              isViewer: viewerHasRole(role: VIEWER)
            }
          }
        }
      }
    }
  }
`;

interface AddNamespaceModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const AddNamespaceModal: React.FC<AddNamespaceModalProps> = ({ handleClose, isOpen }) => {
  const [newNspDispName, setNewNspDispName] = useState(""),
    [newNspName, setNewNspName] = useState(""),
    [newNspDesc, setNewNspDesc] = useState(""),
    [newNspAvatarBlob, setNewNspAvatarBlob] = useState<string | ArrayBuffer | null>(null),
    [newNspAvatar, setNewNspAvatar] = useState<File | undefined>(undefined),
    router = useRouter();

  const handleNewAvatar = (newAvatar: File) => {
    setNewNspAvatar(newAvatar);
    const reader = new FileReader();
    reader.onload = function () {
      setNewNspAvatarBlob(reader.result);
    };
    reader.readAsDataURL(newAvatar);
  };

  const [createNamespace, creatingNamespace] = useMutation<AddNamespaceModal_createNamespaceMutation>(
    AddNamespaceModalCreateNamespaceMutation
  );
  const handleCreateNamespace = () => {
    let uploadables;
    let variables: AddNamespaceModal_createNamespaceMutation$variables = {
      input: {
        name: newNspName,
        displayName: newNspDispName,
        description: newNspDesc,
      },
    };
    if (newNspAvatar) {
      variables.input = {
        ...variables.input,
        avatar: "avatarFile",
      };
      uploadables = { avatarFile: newNspAvatar };
    }
    createNamespace({
      variables,
      uploadables,
      onCompleted: (_response, errors) => {
        if (Array.isArray(errors)) {
          ToasterAlertWarning({ message: errors?.[0]?.message });
        } else {
          ToasterAlertSuccess({ message: `Namespace created successfully` });
          handleClose();
          router.push(`/${newNspName}`);
        }
      },
      onError: (error) => {
        console.error(error);
        ToasterAlertWarning({ message: "An error while creating the namespace" });
      },
    });
  };

  React.useEffect(() => {
    setNewNspName(
      newNspDispName
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^a-zA-Z0-9-]/g, "")
    );
  }, [newNspDispName, setNewNspName]);

  return (
    <Transition.Root as={React.Fragment} show={isOpen}>
      <Dialog onClose={handleClose} className="relative z-50">
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#0F0518]/[15%]" />
        </Transition.Child>
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4" data-cy="add-namespace-modal">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative flex w-full max-w-[480px] flex-col gap-[32px] rounded-[12px] border border-mischka bg-[#FBFBFC] p-[32px]">
              <Close
                onClick={handleClose}
                className="absolute right-[24px] top-[24px] cursor-pointer stroke-[#0F0518]"
              />

              <div className="flex flex-col items-start justify-start gap-3">
                <Dialog.Title className={css.header}>Create a new Namespace</Dialog.Title>
                <Dialog.Description className={css.subHeader}>
                  Namespaces can own apps packages and make collaboration with others easier.
                </Dialog.Description>
              </div>

              <div className="flex flex-col items-start justify-start gap-[24px]">
                <DefaultInputBox
                  fullWidth
                  showIcon={false}
                  placeholder="Full name"
                  label="Full name"
                  value={newNspDispName}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement> | any) => setNewNspDispName(ev.target.value)}
                  data-cy="new-namespace-full-name"
                />
                <InputBoxWithAdorment
                  fullWidth
                  showIcon={false}
                  placeholder="Name"
                  value={newNspName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement> | any) =>
                    setNewNspName(
                      e.target.value
                        .toLowerCase()
                        .replace(/ /g, "-")
                        .replace(/[^a-zA-Z0-9-]/g, "")
                    )
                  }
                  leftAdorment={{
                    text: "wasmer.io/",
                    variant: "none",
                    className: cn(css.nspAdorment, {
                      [css.nspAdrDis]: newNspName.trim() == "",
                      [css.nspAdrActive]: newNspName.trim() != "",
                    }),
                  }}
                  data-cy="new-namespace-name"
                />
                <DefaultInputBox
                  multi
                  rows={4}
                  showIcon={false}
                  fullWidth
                  placeholder="Description"
                  label="Description"
                  value={newNspDesc}
                  onChange={(ev: React.ChangeEvent<HTMLTextAreaElement> | any) => setNewNspDesc(ev.target.value)}
                  data-cy="new-namespace-description"
                />
                <ProfilePicture
                  direction="rtl"
                  avatar={newNspAvatarBlob as string}
                  placeholder="Drop your profile image here"
                  className={css.uploadAvatar}
                  onAvatarChange={handleNewAvatar}
                />
              </div>

              <>
                <div
                  className="flex w-fit items-center justify-center gap-2 self-end"
                  data-cy="create-namespace-btn-wrap"
                >
                  {creatingNamespace && <LoadingIcon />}
                  <ConfirmButton
                    showIcon={false}
                    text="Create namespace"
                    disabled={newNspName.trim() == "" || newNspDispName.trim() == "" || creatingNamespace}
                    onClick={handleCreateNamespace}
                  />
                </div>
              </>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddNamespaceModal;
