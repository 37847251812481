/**
 * @generated SignedSource<<6061b0057117c356bb5f05416eceeaf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppActivityCard_data$data = {
  readonly activeVersion: {
    readonly " $fragmentSpreads": FragmentRefs<"AppRowCard_deployAppVersion">;
  };
  readonly id: string;
  readonly " $fragmentType": "AppActivityCard_data";
};
export type AppActivityCard_data$key = {
  readonly " $data"?: AppActivityCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppActivityCard_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppActivityCard_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeployAppVersion",
      "kind": "LinkedField",
      "name": "activeVersion",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AppRowCard_deployAppVersion"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeployApp",
  "abstractKey": null
};

(node as any).hash = "20de92c019a76f658706f8a078a1e0c5";

export default node;
