/**
 * @generated SignedSource<<96e56a710c9598b5320167752649a221>>
 * @relayHash a9c4eefecf1026da62e78e22a95797da
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a9c4eefecf1026da62e78e22a95797da

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityList_activityPaginationQuery$variables = {
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  id: string;
};
export type ActivityList_activityPaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ActivityListForUser_data">;
  } | null | undefined;
};
export type ActivityList_activityPaginationQuery = {
  response: ActivityList_activityPaginationQuery$data;
  variables: ActivityList_activityPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 6,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "likersCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "downloadsCount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "globalName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "owner",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v9/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v3/*: any*/)
      ],
      "type": "Node",
      "abstractKey": "__isNode"
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "watchersCount",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerHasLiked",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsWatching",
  "storageKey": null
},
v14 = {
  "alias": "typeResult",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "packageName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showDeployButton",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "PackageCollaboratorConnection",
  "kind": "LinkedField",
  "name": "collaborators",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PackageCollaboratorEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PackageCollaborator",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v19/*: any*/),
                (v5/*: any*/),
                (v20/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "favicon",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Package",
  "kind": "LinkedField",
  "name": "sourcePackage",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v16/*: any*/),
    (v6/*: any*/),
    (v17/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "DeployApp",
  "kind": "LinkedField",
  "name": "app",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v10/*: any*/),
    (v3/*: any*/),
    (v24/*: any*/)
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "publishedBy",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    (v19/*: any*/),
    (v20/*: any*/)
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientName",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "screenshot",
  "storageKey": null
},
v30 = [
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivityList_activityPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ActivityListForUser_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActivityList_activityPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "activity",
                "args": (v4/*: any*/),
                "concreteType": "ActivityEventConnection",
                "kind": "LinkedField",
                "name": "publicActivity",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivityEventEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ActivityEvent",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EventBody",
                            "kind": "LinkedField",
                            "name": "body",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "text",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "NodeBodyRange",
                                "kind": "LinkedField",
                                "name": "ranges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "entity",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isNode"
                                      },
                                      (v3/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v5/*: any*/)
                                        ],
                                        "type": "User",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v6/*: any*/),
                                          (v7/*: any*/),
                                          (v8/*: any*/),
                                          (v10/*: any*/),
                                          (v11/*: any*/),
                                          (v12/*: any*/),
                                          (v13/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PackageVersion",
                                            "kind": "LinkedField",
                                            "name": "lastVersion",
                                            "plural": false,
                                            "selections": [
                                              (v14/*: any*/),
                                              (v15/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Package",
                                                "kind": "LinkedField",
                                                "name": "package",
                                                "plural": false,
                                                "selections": [
                                                  (v3/*: any*/),
                                                  (v16/*: any*/),
                                                  (v17/*: any*/),
                                                  (v7/*: any*/),
                                                  (v12/*: any*/),
                                                  (v18/*: any*/),
                                                  (v10/*: any*/),
                                                  (v21/*: any*/)
                                                ],
                                                "storageKey": null
                                              },
                                              (v3/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "Package",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "version",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Package",
                                            "kind": "LinkedField",
                                            "name": "package",
                                            "plural": false,
                                            "selections": [
                                              (v3/*: any*/),
                                              (v16/*: any*/),
                                              (v6/*: any*/),
                                              (v17/*: any*/),
                                              (v10/*: any*/),
                                              (v7/*: any*/),
                                              (v8/*: any*/),
                                              (v11/*: any*/),
                                              (v12/*: any*/),
                                              (v13/*: any*/),
                                              (v18/*: any*/),
                                              (v21/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v14/*: any*/),
                                          (v15/*: any*/)
                                        ],
                                        "type": "PackageVersion",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v9/*: any*/)
                                        ],
                                        "type": "Namespace",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "slug",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "BlogPostTag",
                                            "kind": "LinkedField",
                                            "name": "tags",
                                            "plural": true,
                                            "selections": [
                                              (v3/*: any*/),
                                              (v6/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "coverImageUrl",
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "BlogPost",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v6/*: any*/),
                                          (v10/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "DeployAppVersion",
                                            "kind": "LinkedField",
                                            "name": "activeVersion",
                                            "plural": false,
                                            "selections": [
                                              (v22/*: any*/),
                                              (v23/*: any*/),
                                              (v25/*: any*/),
                                              (v26/*: any*/),
                                              (v24/*: any*/),
                                              (v27/*: any*/),
                                              (v28/*: any*/),
                                              (v29/*: any*/),
                                              (v3/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "DeployApp",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v22/*: any*/),
                                          (v23/*: any*/),
                                          (v25/*: any*/),
                                          (v26/*: any*/),
                                          (v24/*: any*/),
                                          (v27/*: any*/),
                                          (v28/*: any*/),
                                          (v29/*: any*/)
                                        ],
                                        "type": "DeployAppVersion",
                                        "abstractKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "offset",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "length",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": "packageEntity",
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "entity",
                                    "plural": false,
                                    "selections": (v30/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": "packageVersionEntity",
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "entity",
                                    "plural": false,
                                    "selections": (v30/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": "blogPostEntity",
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "entity",
                                    "plural": false,
                                    "selections": (v30/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": "appEntity",
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "entity",
                                    "plural": false,
                                    "selections": (v30/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": "appVersionEntity",
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "entity",
                                    "plural": false,
                                    "selections": (v30/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "actorIcon",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "activity",
                "args": (v4/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "ActivityList_activity",
                "kind": "LinkedHandle",
                "name": "publicActivity"
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "a9c4eefecf1026da62e78e22a95797da",
    "metadata": {},
    "name": "ActivityList_activityPaginationQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a574181f851027bfd28823a0498bc657";

export default node;
