import React from "react";
import { graphql, useFragment } from "react-relay";

import AppRow from "@components/shared/Cards/AppRow";
import { AppVersionActivityCard_data$key } from "@generated/AppVersionActivityCard_data.graphql";

const AppVersionActivityCard_dataFragment = graphql`
  fragment AppVersionActivityCard_data on DeployAppVersion {
    id
    ...AppRowCard_deployAppVersion
  }
`;

interface AppVersionActivityCardProps {
  fragmentRef: AppVersionActivityCard_data$key;
}

const AppVersionActivityCard: React.FC<AppVersionActivityCardProps> = ({ fragmentRef }) => {
  const data = useFragment(AppVersionActivityCard_dataFragment, fragmentRef);

  return <AppRow appVersion={data!} />;
};

export default AppVersionActivityCard;
