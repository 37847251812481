/**
 * @generated SignedSource<<beccc9a5708fcd0f50a2e59fd7ba3560>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppVersionActivityItem_data$data = {
  readonly body: {
    readonly ranges: ReadonlyArray<{
      readonly entity: {
        readonly id?: string;
        readonly " $fragmentSpreads": FragmentRefs<"AppVersionActivityCard_data">;
      };
    }>;
    readonly text: string;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardActivityItemHeader_data">;
  readonly " $fragmentType": "AppVersionActivityItem_data";
};
export type AppVersionActivityItem_data$key = {
  readonly " $data"?: AppVersionActivityItem_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppVersionActivityItem_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppVersionActivityItem_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardActivityItemHeader_data"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EventBody",
      "kind": "LinkedField",
      "name": "body",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NodeBodyRange",
          "kind": "LinkedField",
          "name": "ranges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "entity",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AppVersionActivityCard_data"
                    }
                  ],
                  "type": "DeployAppVersion",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ActivityEvent",
  "abstractKey": null
};
})();

(node as any).hash = "bc47d8beacd78d3c95dd5b792ac23e39";

export default node;
