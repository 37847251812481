import React from "react";
import { graphql, useFragment } from "react-relay";

import EdgeLogo from "@assets/icons/EdgeLogo";
import DashboardActivityItemHeader from "@components/Dashboard/DashboardActivityItemHeader";
import { AppVersionActivityItem_data$key } from "@generated/AppVersionActivityItem_data.graphql";

import AppVersionActivityCard from "./AppVersionActivityCard";

const AppVersionActivityItem_dataFragment = graphql`
  fragment AppVersionActivityItem_data on ActivityEvent {
    ...DashboardActivityItemHeader_data
    id
    body {
      text
      ranges {
        entity {
          ... on DeployAppVersion {
            id
            ...AppVersionActivityCard_data
          }
        }
      }
    }
  }
`;

interface AppVersionActivityItemProps {
  fragmentRef: AppVersionActivityItem_data$key;
  messageOnly?: boolean;
}

const AppVersionActivityItem: React.FC<AppVersionActivityItemProps> = ({ fragmentRef, messageOnly }) => {
  const data = useFragment(AppVersionActivityItem_dataFragment, fragmentRef);
  if (!data?.id) return null;
  const AppVersionActivityCardFragmentRef = data.body?.ranges?.find((range) => !!range.entity?.id)?.entity!;

  return (
    <div className="flex w-full flex-col items-start justify-start gap-3">
      <DashboardActivityItemHeader
        fragmentRef={data}
        icon={<EdgeLogo className="w-[9px] stroke-[#FBFBFC]" />}
        vertical={messageOnly}
      />
      {messageOnly ? null : <AppVersionActivityCard fragmentRef={AppVersionActivityCardFragmentRef} />}
    </div>
  );
};

export default AppVersionActivityItem;
