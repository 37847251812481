/**
 * @generated SignedSource<<4ddba30b5040bc15a19475f8a8e909dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppVersionActivityCard_data$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AppRowCard_deployAppVersion">;
  readonly " $fragmentType": "AppVersionActivityCard_data";
};
export type AppVersionActivityCard_data$key = {
  readonly " $data"?: AppVersionActivityCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppVersionActivityCard_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppVersionActivityCard_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppRowCard_deployAppVersion"
    }
  ],
  "type": "DeployAppVersion",
  "abstractKey": null
};

(node as any).hash = "8c66889e35b73ddd4b662a8511a925d2";

export default node;
