import React from "react";

import { cn } from "@libs/helper";

const ArrowLeft = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("stroke-[#0F0518]", props?.className)}
    >
      <path d="M4.5 7.5l-3-3 3-3" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowLeft;
