/**
 * @generated SignedSource<<99c5453316ed2320eb7038219e21d46c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityList_data$data = {
  readonly __typename: "Namespace";
  readonly " $fragmentSpreads": FragmentRefs<"ActivityListForNamespace_data">;
  readonly " $fragmentType": "ActivityList_data";
} | {
  readonly __typename: "User";
  readonly " $fragmentSpreads": FragmentRefs<"ActivityListForUser_data">;
  readonly " $fragmentType": "ActivityList_data";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "ActivityList_data";
};
export type ActivityList_data$key = {
  readonly " $data"?: ActivityList_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityList_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "count",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "cursor",
    "variableName": "cursor"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 6,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityList_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "ActivityListForUser_data"
        }
      ],
      "type": "User",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "ActivityListForNamespace_data"
        }
      ],
      "type": "Namespace",
      "abstractKey": null
    }
  ],
  "type": "GlobalObject",
  "abstractKey": "__isGlobalObject"
};
})();

(node as any).hash = "670a0e093fe0814f258dadb117698ef8";

export default node;
