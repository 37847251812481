/**
 * @generated SignedSource<<1bc9da7738fcc3e7c25dc38799bc3c74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardActivityItem_data$data = {
  readonly body: {
    readonly ranges: ReadonlyArray<{
      readonly appEntity: {
        readonly id?: string;
      };
      readonly appVersionEntity: {
        readonly id?: string;
      };
      readonly blogPostEntity: {
        readonly id?: string;
      };
      readonly packageEntity: {
        readonly id?: string;
      };
      readonly packageVersionEntity: {
        readonly id?: string;
      };
    }>;
    readonly text: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AppActivityItem_data" | "AppVersionActivityItem_data" | "BlogPostActivityItem_data" | "DashboardActivityItemHeader_data" | "PackageActivityItem_data" | "PackageVersionActivityItem_data">;
  readonly " $fragmentType": "DashboardActivityItem_data";
};
export type DashboardActivityItem_data$key = {
  readonly " $data"?: DashboardActivityItem_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardActivityItem_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardActivityItem_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardActivityItemHeader_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PackageVersionActivityItem_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PackageActivityItem_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppActivityItem_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppVersionActivityItem_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BlogPostActivityItem_data"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventBody",
      "kind": "LinkedField",
      "name": "body",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NodeBodyRange",
          "kind": "LinkedField",
          "name": "ranges",
          "plural": true,
          "selections": [
            {
              "alias": "packageEntity",
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "entity",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": (v0/*: any*/),
                  "type": "Package",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": "packageVersionEntity",
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "entity",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": (v0/*: any*/),
                  "type": "PackageVersion",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": "blogPostEntity",
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "entity",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": (v0/*: any*/),
                  "type": "BlogPost",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": "appEntity",
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "entity",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": (v0/*: any*/),
                  "type": "DeployApp",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": "appVersionEntity",
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "entity",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": (v0/*: any*/),
                  "type": "DeployAppVersion",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ActivityEvent",
  "abstractKey": null
};
})();

(node as any).hash = "3d5494ccebef3431b63ccb19097b5241";

export default node;
