import React from "react";
import { graphql, useFragment } from "react-relay";

import EdgeLogo from "@assets/icons/EdgeLogo";
import DashboardActivityItemHeader from "@components/Dashboard/DashboardActivityItemHeader";
import { AppActivityItem_data$key } from "@generated/AppActivityItem_data.graphql";

import AppActivityCard from "./AppActivityCard";

const AppActivityItem_dataFragment = graphql`
  fragment AppActivityItem_data on ActivityEvent {
    ...DashboardActivityItemHeader_data
    id
    body {
      text
      ranges {
        entity {
          ... on DeployApp {
            id
            ...AppActivityCard_data
          }
        }
      }
    }
  }
`;

interface AppActivityItemProps {
  fragmentRef: AppActivityItem_data$key;
  messageOnly?: boolean;
}

const AppActivityItem: React.FC<AppActivityItemProps> = ({ fragmentRef, messageOnly = false }) => {
  const data = useFragment(AppActivityItem_dataFragment, fragmentRef);
  if (!data?.id) return null;
  const AppActivityCardFragmentRef = data.body?.ranges?.find((range) => !!range.entity?.id)?.entity!;

  return (
    <div className="flex w-full flex-col items-start justify-start gap-3">
      <DashboardActivityItemHeader
        fragmentRef={data}
        icon={<EdgeLogo className="w-[9px] stroke-[#FBFBFC]" />}
        vertical={messageOnly}
      />
      {messageOnly ? null : <AppActivityCard fragmentRef={AppActivityCardFragmentRef} />}
    </div>
  );
};

export default AppActivityItem;
