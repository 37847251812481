import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";

import { cn } from "@libs/helper";

import css from "./AppTag.module.css";
import { rgbDataURL } from "@utils/image";

interface AppTagInterface {
  icon: string;
  fallbackIcon?: string;
  owner: string;
  name: string;
  className?: string;
  reverse?: boolean;
  noLink?: boolean;
}
const AppTag: React.FC<AppTagInterface> = ({ icon, owner, name, className, reverse, noLink }) => {
  return (
    <div className={cn(css.wrapper, reverse && css.reverse, className)}>
      <img className={css.icon} src={icon} width={16} height={16} alt={name} />
      {noLink ? (
        <span className={css.appName}>
          {owner}/{name}
        </span>
      ) : (
        <Link href={`/apps/${owner}/${name}`}>
          <span className={css.appName}>{name}</span>
        </Link>
      )}
    </div>
  );
};
export default AppTag;
