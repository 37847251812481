import React from "react";
import { graphql, useFragment } from "react-relay";

import PackageCard from "@components/PackageCard";
import { PackageVersionActivityCard_data$key } from "@generated/PackageVersionActivityCard_data.graphql";

import styles from "./index.module.css";

const PackageVersionActivityCard_dataFragment = graphql`
  fragment PackageVersionActivityCard_data on PackageVersion {
    id
    ...PackageCard_data
  }
`;

interface PackageVersionActivityCardProps {
  fragmentRef: PackageVersionActivityCard_data$key;
}

const PackageVersionActivityCard: React.FC<PackageVersionActivityCardProps> = ({ fragmentRef }) => {
  const data = useFragment(PackageVersionActivityCard_dataFragment, fragmentRef);
  return <PackageCard className={styles.pkgCardClassName} fragmentRef={data!} forceMobileLayout={true} />;
};

export default PackageVersionActivityCard;
