import { useRouter } from "next/navigation";
import React from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";

import EdgeLogo from "@assets/icons/EdgeLogo";
import { CreateAppButtonOwner_data$key } from "@generated/CreateAppButtonOwner_data.graphql";
import { CreateAppButtonPackage_data$key } from "@generated/CreateAppButtonPackage_data.graphql";
import { CreateAppButton_dataQuery } from "@generated/CreateAppButton_dataQuery.graphql";

import styles from "./index.module.css";

const CreateAppButtonDataQuery = graphql`
  query CreateAppButton_dataQuery {
    viewer {
      id
      username
    }
  }
`;
const CreateAppButtonOwner_dataFragment = graphql`
  fragment CreateAppButtonOwner_data on Namespace {
    id
    globalName
  }
`;
const CreateAppButtonPackage_dataFragment = graphql`
  fragment CreateAppButtonPackage_data on Package {
    id
    globalName
    showDeployButton
  }
`;

interface CreateAppButtonInterface {
  ownerRef?: CreateAppButtonOwner_data$key;
  packageRef?: CreateAppButtonPackage_data$key;
  deploy?: boolean;
}
const CreateAppButton: React.FC<CreateAppButtonInterface> = ({ ownerRef, packageRef, deploy = false }) => {
  const data = useLazyLoadQuery<CreateAppButton_dataQuery>(CreateAppButtonDataQuery, {});
  const ownerData = useFragment(CreateAppButtonOwner_dataFragment, ownerRef!);
  const packageData = useFragment(CreateAppButtonPackage_dataFragment, packageRef!);
  const router = useRouter();
  const handleClick = () => {
    let url = `/apps/create`;
    switch (true) {
      case !!ownerData?.id && !!packageData?.id: {
        url = `/apps/configure?owner=${ownerData.globalName}&package=${packageData.globalName}`;
        break;
      }
      case !!ownerData?.id: {
        url = `/apps/create`;
        break;
      }
      case !!packageData?.id: {
        url = `/apps/configure?package=${packageData?.globalName}`;
        break;
      }
      default:
        break;
    }
    router.push(url);
  };
  if (packageData?.id && !packageData?.showDeployButton) return null;
  return (
    <button onClick={handleClick} className={styles.wrapper} data-cy="create-app-button">
      <EdgeLogo className="w-[14px]" />
      <span className={styles.text}>{deploy ? "Deploy" : "Create app"}</span>
    </button>
  );
};

export default CreateAppButton;
