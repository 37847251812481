/**
 * @generated SignedSource<<48abb461a6c4662bc3175dd6cca6b4d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateAppButtonPackage_data$data = {
  readonly globalName: string;
  readonly id: string;
  readonly showDeployButton: boolean;
  readonly " $fragmentType": "CreateAppButtonPackage_data";
};
export type CreateAppButtonPackage_data$key = {
  readonly " $data"?: CreateAppButtonPackage_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateAppButtonPackage_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateAppButtonPackage_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "globalName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showDeployButton",
      "storageKey": null
    }
  ],
  "type": "Package",
  "abstractKey": null
};

(node as any).hash = "b600102cc588930718aa2a07d4844d3a";

export default node;
