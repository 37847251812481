import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const PlusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("stroke-[#0F0518]", props?.className))}
    >
      <path d="M1.714 5H6m0 0h4.286M6 5V.714M6 5v4.286" strokeWidth={1.5} strokeLinecap="round" />
    </svg>
  );
};

export default PlusIcon;
