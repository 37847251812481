/**
 * @generated SignedSource<<c69dbf6639d8f0a3ca854b7c4a3dfe27>>
 * @relayHash 8a10500225f94084aef60b373db296b7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8a10500225f94084aef60b373db296b7

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateNamespaceInput = {
  avatar?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  description?: string | null | undefined;
  displayName?: string | null | undefined;
  name: string;
};
export type AddNamespaceModal_createNamespaceMutation$variables = {
  input: CreateNamespaceInput;
};
export type AddNamespaceModal_createNamespaceMutation$data = {
  readonly createNamespace: {
    readonly namespace: {
      readonly avatar: string;
      readonly displayName: string | null | undefined;
      readonly globalName: string;
      readonly id: string;
      readonly isAdmin: boolean;
      readonly isEditor: boolean;
      readonly isViewer: boolean;
      readonly name: string;
    };
    readonly user: {
      readonly id: string;
      readonly namespaces: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly avatar: string;
            readonly displayName: string | null | undefined;
            readonly globalName: string;
            readonly id: string;
            readonly isAdmin: boolean;
            readonly isEditor: boolean;
            readonly isViewer: boolean;
            readonly name: string;
          } | null | undefined;
        } | null | undefined>;
      };
    };
  } | null | undefined;
};
export type AddNamespaceModal_createNamespaceMutation = {
  response: AddNamespaceModal_createNamespaceMutation$data;
  variables: AddNamespaceModal_createNamespaceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "globalName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "avatar",
    "storageKey": null
  },
  {
    "alias": "isAdmin",
    "args": [
      {
        "kind": "Literal",
        "name": "role",
        "value": "ADMIN"
      }
    ],
    "kind": "ScalarField",
    "name": "viewerHasRole",
    "storageKey": "viewerHasRole(role:\"ADMIN\")"
  },
  {
    "alias": "isEditor",
    "args": [
      {
        "kind": "Literal",
        "name": "role",
        "value": "EDITOR"
      }
    ],
    "kind": "ScalarField",
    "name": "viewerHasRole",
    "storageKey": "viewerHasRole(role:\"EDITOR\")"
  },
  {
    "alias": "isViewer",
    "args": [
      {
        "kind": "Literal",
        "name": "role",
        "value": "VIEWER"
      }
    ],
    "kind": "ScalarField",
    "name": "viewerHasRole",
    "storageKey": "viewerHasRole(role:\"VIEWER\")"
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateNamespacePayload",
    "kind": "LinkedField",
    "name": "createNamespace",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Namespace",
        "kind": "LinkedField",
        "name": "namespace",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NamespaceConnection",
            "kind": "LinkedField",
            "name": "namespaces",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NamespaceEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Namespace",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddNamespaceModal_createNamespaceMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddNamespaceModal_createNamespaceMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "8a10500225f94084aef60b373db296b7",
    "metadata": {},
    "name": "AddNamespaceModal_createNamespaceMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "704b697fa4a32537bd57c39b707580fc";

export default node;
